<template>
  <q-header elevated class="bg-primary text-white" height-hint="98">
    <q-toolbar>
      <q-btn dense flat round icon="menu" @click="clickDrawerIcon" />

      <q-space/>

      <!-- <q-btn round dense flat color="white-8" icon="notifications">
        <q-badge color="red" text-color="white" floating>
          2
        </q-badge>
        <q-tooltip>알림</q-tooltip>
      </q-btn>

      <q-btn round dense flat color="white-8" icon="message" v-if="$q.screen.gt.sm">
        <q-tooltip>메일</q-tooltip>
      </q-btn> -->

      <q-btn flat round dense icon="account_circle">
          <q-menu
              transition-show="jump-down"
              transition-hide="jump-up"
          >
            <q-list class="profile-menu" style="width:140px;">
              <q-item clickable>
                <q-item-section>
                  <span class="text-weight-bold">{{loginUser.nickName}}님</span>
                </q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-if="loginUser.authority==='ROLE_SYSTEM_ADMIN'">
                <q-item-section @click="goUserList">회원목록</q-item-section>
              </q-item>
              <q-item clickable>
                <q-item-section @click="actModifyUser">회원정보 수정</q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable>
                <q-item-section @click="logout">로그아웃</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

    </q-toolbar>

  </q-header>
</template>

<script>

export default {
  name: "AppBar",
  computed:{
    loginUser(){
      console.log("로그인 유저",this.$store.getters["auth/getLoginUser"])
      return this.$store.getters["auth/getLoginUser"]
    }
  },
  methods: {
    clickDrawerIcon() {
      this.$store.dispatch("ui/toggleSideDrawer")
    },
    actModifyUser(){
      this.$store.dispatch("user/toggleModifyUser")
    },
    goUserList(){
      this.$router.push('/user')
    },
    logout(){
      this.$root.$refs.alert.open({
        type: "help",
        btnType: "cancel",
        text: "로그아웃 하시겠습니까?"

      }).then(result => {
        if (result) {
          this.$store.dispatch('auth/logout', this.loginUser.userId)
              .then(() => this.$router.push('/login'))
        }
      })
    }
  },
  created(){

  }
}
</script>

<style scoped>

</style>
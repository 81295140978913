import httpWithOutHeader from '@/store/http-without-header';

const getDefaultState = () =>{
    return {
        token : undefined,
        loginUser: undefined,
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/auth' : process.env.VUE_APP_DOMAIN_URL || '/auth';

export const auth ={
    namespaced: true,
    state : getDefaultState(),
    getters:{
        getToken(state){
            return state.token
        },
        isAuthenticated(state){
            return state.token !== undefined
        },
        getLoginUser(state){
            return state.loginUser || {}
        },
        isAdmin(state){
            const adminAuthorities = ["ROLE_SYSTEM_ADMIN"]
            return state.loginUser && adminAuthorities.includes(state.loginUser.authority)
        },
    },
    mutations:{
        changeState(state,newState){
            state = newState
        },
        changeToken(state,token){
            state.token=token
        },
        changeLoginUser(state, user) {
            state.loginUser = user
        },
    },
    actions:{
        setState({commit},newState){
            commit("changeState",newState)
        },
        login({commit},user){
            return new Promise((resolve,reject)=>{
                httpWithOutHeader.post(DOMAIN_URL+"/login",{
                    userId:user.userId,
                    password:user.password
                }).then(response=>{
                    console.log("login action return data",response)
                    const data = response.data.data
                
                    commit("changeToken",data.token)
                    commit("changeLoginUser",{
                        userId: data.username,
                        nickName:data.nickname,
                        authority: data.authority,
                        idx: data.idx
                    })
                    resolve()
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        logout({commit}, userId) {
            return new Promise((resolve, reject) => {
                httpWithOutHeader.get(DOMAIN_URL + "/logout", {params: {userId}})
                    .then(() => {
                        commit("changeToken", undefined)
                        commit("changeLoginUser", undefined)
                        localStorage.removeItem('vuex')
                        resolve()
                    }).catch((error) => reject(error))
            })
        },
        reissue({commit}, token){
            return new Promise((resolve,reject)=>{
                httpWithOutHeader.post(DOMAIN_URL+"reissue",token)
                    .then(response=>{
                        commit("changeToken",response.data.data)
                        resolve()
                    }).catch(error=>reject(error))
            })
        }

    }
}
import http from "@/store/http-without-header"
import DepositModel from "@/models/Deposit"

const getDefaultState = () => {
    return {
        deposit : new DepositModel()
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/deposit' : process.env.VUE_APP_DOMAIN_URL || '/deposit';

export const deposit = {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        deposit(state){
            return state.deposit
        }
    },
    mutations:{
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        changeState (state, deposit) {
            state.deposit.set(deposit)
        },
    },
    actions:{
        resetState ({ commit }) {
            commit("resetState")
        },
        changeState ({ commit },deposit) {
            commit("changeState", deposit)
        },
        // eslint-disable-next-line no-unused-vars
        search({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/getDepositList', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        register({commit}, deposit){
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/registerDeposit',deposit)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        find({commit}, searchCondition){
            commit("resetState")
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL + "/getDepositInfo", { params: searchCondition })
                    .then(response => {
                        commit("changeState", response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        modify({commit},account){
            return new Promise((resolve,reject) =>{
                http.post(DOMAIN_URL + "/editAccount",account)
                    .then(response => resolve(response.data.result))
                    .catch(error => reject(error))
            })
        }
    }
}
export const ui = {
    namespaced: true,
    state: {
        isOpenSideDrawer: false,
        isLoading: false,
        progress: {
            isShow: false,
            isCompleted: false,
            totalFileCnt: 1,
            curFileIdx: 1,
            progress: 0,
            progressLabel: "0.00%",
            result: false
        }
    },
    getters: {
        isOpenSideDrawer(state) {
            return state.isOpenSideDrawer
        },
        isLoading(state) {
            return state.isLoading
        },
        progress(state) {
            return state.progress
        }
    },
    mutations: {
        toggleSideDrawer(state) {
            state.isOpenSideDrawer = !state.isOpenSideDrawer
        },
        changeLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        toggleProgress(state, isShow) {
            state.progress.isShow = isShow
            state.progress.progress = 0
        },
        changeProgress(state, options) {
            state.progress.totalFileCnt = options.totalFileCnt
            state.progress.curFileIdx = options.curFileIdx
            state.progress.progress = options.progress
            state.progress.progressLabel = Math.round(options.progress * 10000) / 100 + "%"
        }

    },
    actions: {
        toggleSideDrawer({ commit }) {
            commit("toggleSideDrawer")
        },
        changeLoading({ commit }, isLoading) {
            commit("changeLoading", isLoading)
        },
        toggleProgress({ commit }, isShow) {
            commit("toggleProgress", isShow)
        },
        changeProgress({ commit }, options) {
            commit("changeProgress", options)
        }
    },
}
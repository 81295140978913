<template>
  <Transition name="fade">
    <div v-if="isShow">
      <div class="fullscreen dimmed" />

      <div class="fullscreen">
        <div class="absolute-center column">
          <div class="text-center">
            <q-spinner-hourglass
                class="col "
                color="primary"
                size="128px"
                :thickness="10"
            />
          </div>

          <span class="text-white text-subtitle1">잠시만 기다려 주세요...</span>
        </div>

      </div>
    </div>
  </Transition>

</template>

<script>
export default {
  name: "BasicSpinner",
  computed: {
    isShow() {
      return this.$store.getters["ui/isLoading"]
    }
  }
}
</script>

<style scoped>

</style>
export default class UserModel {
    constructor(){
        this.userId = undefined
        this.password = undefined
        this.passwordCk = undefined
        this.nickname = undefined
        this.email = undefined
        this.authority = undefined
    }

    set(user){
        this.userId = user.userId
        this.password = user.password
        this.passwordCk = user.passwordCk
        this.nickname = user.nickname
        this.email = user.email
        this.authority = user.authority
    }
}
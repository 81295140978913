import http from "@/store/http-without-header"
import BankBookModel from "@/models/BankBook"

const getDefaultState = () => {
    return {
        bankBook : new BankBookModel()
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/bank' : process.env.VUE_APP_DOMAIN_URL || '/bank';

export const bankBook = {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        bankBook(state){
            return state.bankBook
        }
    },
    mutations:{
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        changeState (state, bankBook) {
            state.bankBook.set(bankBook)
        },
    },
    actions:{
        resetState ({ commit }) {
            commit("resetState")
        },
        changeState({ commit }, bankBook){
            commit("changeState",bankBook)
        },
        // eslint-disable-next-line no-unused-vars
        search({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/getBankBookList', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        register({commit}, bankBook){
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/register',bankBook)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        modify({commit}, bankBook){
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/editBankBook',bankBook)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        find({commit}, searchCondition){
            commit("resetState")
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL + "/bankBook/", searchCondition.idx)
                    .then(response => {
                        commit("changeState", response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        delete({commit}, bankBook){
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/disableBankBook',bankBook)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },

    }
}
<template>
  <q-layout view="lHh Lpr lFf"   class="shadow-2 rounded-borders">
    <router-view name="AppHeader"/>
    <router-view name="SideDrawer" />
    <q-page-container>
      <router-view/>
    </q-page-container>
    <BasicSpinner />
    <BasicDialog ref="alert"/>
    <BasicProgress />
    <UserModify/>
  </q-layout>
</template>

<script>
import {useQuasar} from 'quasar'
import BasicDialog from "@/components/common/util/BasicDialog";
import BasicSpinner from "@/components/common/util/BasicSpinner";
import UserModify from "@/components/common/UserModify.vue"
// import BasicProgress from "@/components/common/util/BasicProgress";

export default {
  name: 'LayoutDefault',
  components: {
    // BasicProgress,
    BasicSpinner,
    BasicDialog,
    UserModify,
  },
  computed:{
    loggedIn(){
      return this.$store.getters["auth/getLoggedIn"]
    }
  },
  created(){
    const persistStorage = JSON.parse(localStorage.getItem("vuex"))
    if (persistStorage && persistStorage.auth) {
      this.$store.dispatch("auth/setState", persistStorage.auth)
    }
  },
  head() {
    return {
      htmlAttrs: { lang: 'ko' }
    }
  },
  setup() {
    const $q = useQuasar()
    $q.dark.set(false)
  }
}
</script>


<style lang="scss">
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
html, body {
  background-color: $grey-2;
}
</style>

<style lang="css">
    .q-table tbody td {
        font-size: 16px;
    }
    .q-table th {
        font-size: 16px;
        /* font-weight: 800; */
    }
</style>

export default class DepositModel {
    constructor(){
        this.idx = undefined
        this.bankBookIdx = undefined
        this.accountIdx	= undefined
        this.accountName = undefined
        this.orderDate = undefined
        this.orderName = undefined
        this.orderDateTime = undefined
        this.depositAmount = undefined
        this.correctionAmount = undefined
        this.memberIdx = undefined
        this.memberName = undefined
    }

    set(deposit){
        this.idx = deposit.idx
        this.bankBookIdx = deposit.bankBookIdx
        this.accountIdx	= deposit.accountIdx
        this.accountName = deposit.accountName
        this.orderDate = deposit.orderDate
        this.orderName = deposit.orderName
        this.orderDateTime = deposit.orderDateTime
        this.depositAmount = deposit.depositAmount
        this.correctionAmount = deposit.correctionAmount
        this.memberIdx = deposit.memberIdx
        this.memberName = deposit.memberName
    }
}
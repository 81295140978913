import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import { ui } from "@/store/modules/ui.module"
import { account } from "@/store/modules/account.module"
import { orderSheet } from "@/store/modules/ordersheet.module";
import { bankBook } from "@/store/modules/bankbook.module";
import { deposit } from "@/store/modules/deposit.module"
import { user } from "@/store/modules/user.module"
import {auth} from "@/store/modules/auth.module"


export default createStore({
    state:{

    },
    getters:{

    },
    mutations:{

    },
    actions:{

    },
    modules:{
        ui,
        account,
        orderSheet,
        bankBook,
        deposit,
        user,
        auth,
    },
    plugins:[
        //todo persistedState 사용예정
        createPersistedState({
            paths:["auth","orderSheet","account"]
        })
    ]
})
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

createApp(App)
    .use(router)
    .use(store)
    .use(Quasar, quasarUserOptions)
    .mount('#app')

export default class AccountModel {
    constructor(){
        this.accountFee	= undefined
        this.accountName = undefined
        this.accountCreditAmount = undefined
        this.address = undefined
        this.businessNumber	= undefined
        this.createdDate = undefined
        this.depositorName = undefined
        this.idx = undefined
        this.isEnable = undefined
        this.lastModifiedDate = undefined
        this.mobileNumber = undefined
        this.smsMessage	= false
        this.taxbill = false
    }

    set(account){
        this.accountFee	= account.accountFee
        this.accountName = account.accountName
        this.accountCreditAmount = account.accountCreditAmount
        this.address = account.address
        this.businessNumber	= account.businessNumber
        this.createdDate = account.createdDate
        this.depositorName = account.depositorName
        this.idx = account.idx
        this.isEnable = account.isEnable
        this.lastModifiedDate = account.lastModifiedDate
        this.mobileNumber = account.mobileNumber
        this.smsMessage	= account.smsMessage
        this.taxbill = account.taxbill
    }
}
export default class BankBookModel {
    constructor(){
        this.idx	= undefined
        this.bankName = undefined
        this.bankNickName = undefined
        this.bankAccountName	= undefined
        this.bankAccountNumber = undefined
        this.balance = undefined
        this.isEnable = undefined
    }

    set(bankBook){
        this.idx	= bankBook.idx
        this.bankName = bankBook.bankName
        this.bankNickName = bankBook.bankNickName
        this.bankAccountName	= bankBook.bankAccountName
        this.bankAccountNumber = bankBook.bankAccountNumber
        this.balance = bankBook.balance
        this.isEnable = bankBook.isEnable
    }
}
<template>
  <q-drawer
      v-model="isOpenSideNav"
      side="left"
      show-if-above
      bordered
  >

    <!-- drawer top -->
    <div class="drawer-top absolute-top">
      <!-- 로고
      <div class="drawer-logo-section q-py-md q-px-sm">
        <q-toolbar class="row">
          <q-img class="col-13" src="@/assets/images/system_logo.png" />
          <br/>
          
        </q-toolbar>
      </div>
      <q-separator class="shadow-3" />
      <q-separator/>
-->
      <!-- 프로필 -->
      <q-toolbar class="drawer-profile-section q-pa-md">
        <q-space/>
        <div class="text-weight-bold">
          <div class="text-h4">
            중매인 <q-badge color="primary">ver 1.0</q-badge>
          </div> 
        </div>
        <q-space/>

      </q-toolbar>

      <q-separator/>

    </div>

    <!-- drawer content -->
    <q-scroll-area class="drawer-content">
      <q-list>
       
       <!-- 거래처 관리-->
        <q-item
            :content-inset-level="0.5"
            clickable
            v-ripple
            to="/account"
        >
          <q-item-section avatar>
            <q-icon name="account_box"/>
          </q-item-section>

          <q-item-section>
            거래처 관리
          </q-item-section>
        </q-item>
        <!-- //거래처 관리 -->

        <!-- 매입 관리 backup-->
        <!-- <q-item
            :content-inset-level="0.5"
            clickable
            v-ripple
            to="/purchase"
        >
          <q-item-section avatar>
            <q-icon name="local_offer"/>
          </q-item-section>

          <q-item-section>
            매입 관리
          </q-item-section>
        </q-item> -->
        <!-- //매입 관리 -->


        <!-- 매입 관리 -->
        <!-- <q-expansion-item
            group="side-drawer"
            expand-separator
            :content-inset-level="0.5"
            icon="local_offer"
            label="매입 관리"
        >

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/purchase"
          >

            <q-item-section avatar>
              <q-icon name="view_list"/>
            </q-item-section>

            <q-item-section>
              매입 목록
            </q-item-section>
          </q-item>

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/purchase/regist"
          >

            <q-item-section avatar>
              <q-icon name="create"/>
            </q-item-section>

            <q-item-section>
              매입 등록
            </q-item-section>
          </q-item>
        </q-expansion-item> -->
        <!-- //판매 관리 -->





        <!-- 판매 관리 -->
        <q-expansion-item
            group="side-drawer"
            expand-separator
            :content-inset-level="0.5"
            icon="receipt_long"
            label="판매 관리"
        >

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/orderSheet"
          >

            <q-item-section avatar>
              <q-icon name="view_list"/>
            </q-item-section>

            <q-item-section>
              일일 판매 목록
            </q-item-section>
          </q-item>

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/orderSheet/regist"
          >

            <q-item-section avatar>
              <q-icon name="create"/>
            </q-item-section>

            <q-item-section>
              일일 판매 등록
            </q-item-section>
          </q-item>
        </q-expansion-item>
        <!-- //판매 관리 -->

        <!-- 정산 관리-->
        <q-expansion-item
            group="side-drawer"
            expand-separator
            :content-inset-level="0.5"
            icon="paid"
            label="정산 관리"
        >

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/bankBook"
          >

            <q-item-section avatar>
              <q-icon name="view_list"/>
            </q-item-section>

            <q-item-section>
              통장 목록
            </q-item-section>
          </q-item>

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/deposit"
          >

            <q-item-section avatar>
              <q-icon name="view_list"/>
            </q-item-section>

            <q-item-section>
              입금 내역
            </q-item-section>
          </q-item>
        </q-expansion-item>
        
        <!-- <q-item
            :content-inset-level="0.5"
            clickable
            v-ripple
            to="/deposit"
        >
          <q-item-section avatar>
            <q-icon name="paid"/>
          </q-item-section>

          <q-item-section>
            입금 내역
          </q-item-section>
        </q-item> -->
        <!-- //입금 내역 -->

        <!-- 거래명세서 관리 -->
        <q-expansion-item group="side-drawer" expand-separator :content-inset-level="0.5" icon="receipt_long" label="거래명세서 관리">

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/receipt"
          >

            <q-item-section avatar>
              <q-icon name="view_list"/>
            </q-item-section>

            <q-item-section>
              전체 거래 명세서 발송
            </q-item-section>
          </q-item>

          <q-item
              :content-inset-level="0.5"
              clickable
              v-ripple
              to="/receipt/receive"
          >

            <q-item-section avatar>
              <q-icon name="list"/>
            </q-item-section>

            <q-item-section>
              문자 발송 내역
            </q-item-section>
          </q-item>
        </q-expansion-item>
        <!-- //거래명세서 관리 -->
       

  
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
export default {
  name: "SideDrawer",
  data() {
    return {
      /* 임시 코드 */
      username: "Razvan Stoenescu",
      sites: [],
      selectedSite: undefined,
      globalSiteNo: undefined
    }
  },
  computed: {
    isOpenSideNav: {
      get() {
        return this.$store.getters["ui/isOpenSideDrawer"]
      },
      set() {
        this.$store.dispatch("ui/toggleSideDrawer")
      },
    },
  },
  methods: {
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>


  $logo-section-height: 0px;
  $profile-section-height: 80px;
  $total-padded-height: 0px;
  $drawer-top-height: $logo-section-height + $profile-section-height + $total-padded-height;

  .q-item, .q-expansion-item{
    font-size: 1.2em;
    font-weight: 700;
  }

  .q-expansion-item{
    .q-item{
      font-size: 1em !important;
      font-weight: 700;
    }
  }


  .q-drawer {
    .drawer-top {
      height: $drawer-top-height;

      // 로고
      .drawer-logo-section {
        height: $logo-section-height;

      }
      // 프로필
      .drawer-profile {
        height: $profile-section-height;
        margin-top: $logo-section-height;
      }
    }

    .drawer-content {
      height: calc(100% - #{$drawer-top-height});
      margin-top: $drawer-top-height;
    }
  }
</style>

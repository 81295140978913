<template>
  <q-dialog v-model="isModifyUser">
      <q-card style="width: 400px; " class="q-px-sm q-pb-md">
        <q-card-section class="row">
          <div class="text-h6 col">회원정보 수정</div>
          <q-space />
        </q-card-section>
        <q-separator class="shadow-3" />

        <q-card-section>
          <q-input v-model="text" label="아이디"  />
          <q-input v-model="text" label="이름"  />
          <q-input v-model="text" label="연락처" />
          <q-input v-model="text" label="이메일" />
          <q-input v-model="text" label="비밀번호" placeholder="수정하지 않는 경우 입력 금지"/>
          <q-input v-model="text" label="비밀번호 재확인" placeholder="수정하지 않는 경우 입력 금지"/>
        </q-card-section>
         <q-card-actions align="center">
          <q-btn v-close-popup @click="failTest"  label="취소" />
          <q-btn v-close-popup @click="modifyUser" color="primary" label="확인" />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<script>

export default {
  name: "UserModify",
  methods: {
    clickDrawerIcon() {
      this.$store.dispatch("ui/toggleSideDrawer")
    },
    actModifyUser(){
      this.$store.dispatch("user/toggleModifyUser")
    },
    modifyUser(){
      this.$root.$refs.alert.open({
        type:"success",
        btnType:"ok",
        text:"회원 정보 수정이 완료 되었습니다."
      })
    },
    failTest(){
        this.$root.$refs.alert.open({
        type:"error",
        btnType:"ok",
        text:"회원정보 수정 실패",
        detail:"재시도하거나 관리자에게 문의하세요"
      })
    }
  },
  computed: {
    isModifyUser: {
            get() {
                return this.$store.getters["user/isModifyUser"]
            },
            set() {
                this.$store.dispatch("user/toggleModifyUser")
            },
        },
    },
  created(){
  }
}
</script>

<style scoped>

</style>
import http from "@/store/http-without-header"
import AccountModel from "@/models/Account"

const getDefaultState = () => {
    return {
        account : new AccountModel()
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/account' : process.env.VUE_APP_DOMAIN_URL || '/account';

export const account = {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        account(state){
            return state.account
        }
    },
    mutations:{
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        changeState (state, account) {
            state.account.set(account)
        },
    },
    actions:{
        resetState ({ commit }) {
            commit("resetState")
        },
        changeState ({ commit },account) {
            commit("changeState",account)
        },
        // eslint-disable-next-line no-unused-vars
        search({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/getAccountList', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        searchByKeyword({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/searchRecommendAccount', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        register({commit}, account){
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/registerAccount',account)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        find({commit}, idx){
            commit("resetState")
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL + "/getAccount/" + idx)
                    .then(response => {
                        commit("changeState", response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        modify({commit},account){
            return new Promise((resolve,reject) =>{
                http.post(DOMAIN_URL + "/editAccount",account)
                    .then(response => resolve(response.data.result))
                    .catch(error => reject(error))
            })
        }
    }
}
import {createRouter, createWebHistory} from 'vue-router'
import $store from "@/store"
import AppHeader from '@/components/common/AppHeader.vue'
import SideDrawer from '@/components/common/SideDrawer.vue'

const routes= [
    {
        path:'/login',
        components: {
            default: () => import('@/views/user/UserLogin')
        },
        meta: {
            requireAuth: false
        }
    },
    {
        path:'/',
        components: {
            AppHeader,
            SideDrawer,
            default: () => import('@/views/account/AccountList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/account',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/account/AccountList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/account/:idx',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/account/AccountDetail')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/purchase',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/purchase/PurchaseList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/purchase/regist',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/purchase/PurchaseRegist')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/orderSheet',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/orderSheet/OrderSheetList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/orderSheet/detail',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/orderSheet/OrderSheetDetail')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/orderSheet/regist',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/orderSheet/OrderSheetRegist')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/bankBook',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/bankBook/bankBookList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/sale',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/sale/SaleList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/sale/detail',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/sale/SaleDetail')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/sale/regist',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/sale/SaleRegist')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/deposit',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/deposit/depositList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/deposit/regist',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/deposit/depositRegist')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/receipt',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/receipt/ReceiptList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/receipt/receive',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/deposit/depositList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/user',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/user/UserList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/mobile/bill',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/mobile/BillList')
        },
        meta: {
            requireAuth: true
        }
    },
    {
        path:'/mobile/bill/detail',
        components:{
            AppHeader,
            SideDrawer,
            default: () => import('@/views/mobile/BillDetail')
        },
        meta: {
            requireAuth: true
        }
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    
    if(to.fullPath=='/mobile/bill' || to.fullPath=='/mobile/bill/detail'){
        next()
    }
    
    // to: 이동할 url에 해당하는 라우팅 객체
    if (to.matched.some((routeInfo) => {
        return routeInfo.meta.requireAuth === $store.getters["auth/isAuthenticated"]
    })) {
        next() // 페이지 전환

    } else { /* 라우팅 권한과 user의 권한이 다를 경우 */
        /* user가 권한이 없을 때 */
        if (!$store.getters["auth/isAuthenticated"]) {
            next("/login")

        } else {
            next("/")
        }
    }
  });

export default router
export default class OrderSheetModel {
    constructor(){
        this.accountName = undefined
        this.orderItemName = undefined
        this.orderDateTime	= undefined
        this.orderPrice = undefined
        this.boardingFee = undefined
        this.commission = undefined
        this.etc1 = undefined
        this.etc2 = undefined
        this.etc3 = undefined
        this.accountCreditAmount = undefined
        this.itemTotalPrice = undefined
        this.totalPrice = undefined
        this.memo = undefined
        this.orderDate = undefined
        this.orderPriceWon = undefined
        this.saleList = []
    }

    set(orderSheet){
        this.accountName = orderSheet.accountName
        this.orderItemName = orderSheet.orderItemName
        this.orderDateTime	= orderSheet.orderDateTime
        this.orderPrice = orderSheet.orderPrice
        this.boardingFee = orderSheet.boardingFee
        this.commission = orderSheet.commission
        this.etc1 = orderSheet.etc1
        this.etc2 = orderSheet.etc2
        this.etc3 = orderSheet.etc3
        this.accountCreditAmount = orderSheet.accountCreditAmount
        this.itemTotalPrice = orderSheet.itemTotalPrice
        this.totalPrice = orderSheet.totalPrice
        this.memo = orderSheet.memo
        this.orderDate = orderSheet.orderDate
        this.orderPriceWon = orderSheet.orderPriceWon
        this.saleList = orderSheet.saleList
    }
}
import axios from "axios"
import $store from "@/store"
import ApiError from "@/store/ApiError"

/* 인스턴스 생성 */
const instance = axios.create({
    baseURL: process.env.VUE_APP_API
    //baseURL: 'http://erp-api.styleworks.co.kr/api/v1'
})

/* 요청 인터셉터 */
instance.interceptors.request.use(
    config => {

        $store.dispatch("ui/changeLoading", true)

        /* 요청 성공 직전 호출 */
        return config
    },
    error => {
        /* 요청 에러 직전 호출 */
        return Promise.reject(error)
    }
)

/* 응답 인터셉터 */
instance.interceptors.response.use(
    response => {
        $store.dispatch("ui/changeLoading", false)

        return response
    },
    error => {
        $store.dispatch("ui/changeLoading", false)

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const status = error.response?.status || 500;
        console.log(error)
        // we can handle global errors here
        switch (status) {
            // authentication (token related issues)
            case 401: {
                return Promise.reject(new ApiError(error.message, "로그인 실패", "접속정보가 일치하지 않습니다."));
            }

            // forbidden (permission related issues)
            case 403: {
                return Promise.reject(new ApiError(error.message, 409));
            }

            // // not found
            case 400:
            case 404: { // bad request
                console.log(error.response.data)
                const errors = error.response.data
                const text = '오류 코드: '+errors.status
                const detail = errors.message
                return Promise.reject(new ApiError(status, text, detail));
            }

            // generic api error (server related) unexpected
            case 500: {
                const errors = error.response.data
                const text = '오류 코드: '+errors.code
                const detail = errors.message
                return Promise.reject(new ApiError(status, text, detail));
            }
            default: {
                return Promise.reject();
            }
        }
    }

)


export default instance
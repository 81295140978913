import http from "@/store/http-without-header"
import User from "@/models/User"

const getDefaultState = () => {
    return {
        user : new User()
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/user' : process.env.VUE_APP_DOMAIN_URL || '/user';

export const user = {
    namespaced: true,
    state: {
        user: getDefaultState(),
        isModifyUser: false,
    },
    getters:{
        isModifyUser(state){
            return state.isModifyUser
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        toggleModifyUser(state){
            state.isModifyUser = !state.isModifyUser
        },
        resetState (state) {
            Object.assign(state.user, getDefaultState())
        },
    },
    actions:{
        toggleModifyUser({ commit }) {
            commit("toggleModifyUser")
        },
        // eslint-disable-next-line no-unused-vars
        search({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/getMemberList', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
    }
}
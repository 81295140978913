import http from "@/store/http-without-header"
import OrderSheetModel from "@/models/OrderSheet"

const getDefaultState = () => {
    return {
        orderSheet : new OrderSheetModel()
    }
}

const DOMAIN_URL = process.env.VUE_APP_API ? process.env.VUE_APP_API + '/orderSheet' : process.env.VUE_APP_DOMAIN_URL || '/orderSheet';

export const orderSheet = {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        orderSheet(state){
            return state.orderSheet
        }
    },
    mutations:{
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        changeState (state, orderSheet) {
            state.orderSheet.set(orderSheet)
        },
    },
    actions:{
        resetState ({ commit }) {
            commit("resetState")
        },
        changeState ({ commit },orderSheet) {
            commit("changeState",orderSheet)
        },
        // eslint-disable-next-line no-unused-vars
        search({ commit }, searchCondition) { 
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL+'/getOrderSheetList', { params: searchCondition })
                    .then(response => {
                        resolve(response.data.result.orderSheetList)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        register({commit}, orderSheet){
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject)=>{ 
                http.post(DOMAIN_URL+'/registerOrderSheet',orderSheet)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        find({commit}, searchCondition){
            commit("resetState")
            return new Promise((resolve, reject) => {
                http.get(DOMAIN_URL + "/getOrderSheetInfo", { params: searchCondition })
                    .then(response => {
                        commit("changeState", response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        // modify({commit},account){
        //     return new Promise((resolve,reject) =>{
        //         http.post(DOMAIN_URL + "/editAccount",account)
        //             .then(response => resolve(response.data.result))
        //             .catch(error => reject(error))
        //     })
        // }
    }
}
<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="alert-box">
      <q-card-section class="row items-center">
        <q-avatar size="80px" :icon="statusMap[type]['icon']" :text-color="statusMap[type]['color']"/>
        <span class="q-ml-sm text-weight-bold text-subtitle2" style="font-size: 1.2em;">{{ text }}</span>
        <span class="q-ml-sm text-weight-bold text-subtitle2" style="font-size: 1.2em;">
          <span v-html="detail"/>
        </span>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn text-color="negative" flat label="CANCEL" @click="cancel" v-if="btnType === 'cancel'"/>
        <q-btn flat label="OK" @click="ok"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>

export default {
  name: "BasicDialog",
  data() {
    return {
      statusMap: {
        info: {icon: "o_info", color: "info"},
        help: {icon: "help_outline", color: "info"},
        success: {icon: "o_check_circle", color: "positive"},
        warning: {icon: "o_report_problem", color: "warning"},
        error: {icon: "error_outline", color: "negative"},
      },
      isShow: false,
      type: "info",
      btnType: "ok", // ok, cancel
      text: "",
      detail: "",
      result: undefined
    }
  },
  methods: {
    open(options) {
      const defaultOption = {
        type: 'info',
        btnType: 'ok',
        text: ''
      }

      options = Object.assign({}, defaultOption, options)
      this.isShow = true
      this.type = options.type === (null || '') ? 'info' : options.type
      this.btnType = options.btnType === (null || '') ? 'ok' : options.btnType
      this.text = options.text
      this.detail = options.detail
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.result = resolve
      })
    },
    ok() {
      this.isShow = false
      this.result(true)
    },
    cancel() {
      this.isShow = false
      this.result(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert-box {
    width: 400px
  }

</style>